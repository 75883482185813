import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, Grid, Skeleton } from "@mui/material";
import { highPaidJobs, jobDetails } from "../../../fetchAPI";
import { Link } from "react-router-dom";
import { GetHighPaidJobsSliderProps } from "../Slider/SliderFunctions";
import SliderComponent from "../Slider/Slider";
import { JobSearchContext } from "../../context/JobSearchContext";
import { useTranslation } from "react-i18next";

function HighPaidJobs() {
  const [highJobs, setHighJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setHighPaid } = useContext(JobSearchContext);
  console.log(setHighPaid);

  //translation
  const { t } = useTranslation(["homepage", "button"]);

  useEffect(() => {
    setIsLoading(true);
    gettingTopJobs();
  }, []);

  const gettingTopJobs = () => {
    highPaidJobs().then((response) => {
      if (response?.success) {
        setHighJobs(response?.response?.data);
        setIsLoading(false);
      } else {
        // console.log('errors: ', response);
      }
    });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      // partialVisibilityGutter: -38
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  /**
   * Slider Component
   * 2023-12-18 adachi
   */
  const [slides, setSlides] = useState();
  const [params, setParams] = useState({});
  useEffect(() => {
    if (isLoading) {
      return;
    }
    const { slides, params } = GetHighPaidJobsSliderProps({
      jobs: highJobs?.high_paid_job,
      t: t,
    });
    setSlides(slides);
    setParams(params);
  }, [highJobs, isLoading, t]);
  const slider = useMemo(() => {
    if (isLoading) {
      return null;
    }
    return <SliderComponent slides={slides} params={params} />;
  }, [slides, params, isLoading]);

  return (
    <div className="m-b100 mt-5">
      <div className="container">
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: "top",
          }}
        >
          <Grid item>
            {isLoading ? (
              <>
                <Grid
                  container
                  spacing={1}
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  <Grid item>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      width={400}
                      height={40}
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      width={40}
                      height={40}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <h1
                className="d-none d-lg-block"
                style={{
                  color: "#424447",
                  fontWeight: 700,
                  fontSize: "24px",
                  fontFamily: "Epilogue,sans-serif",
                }}
              >
                {t("homepage:high-paid-jobs.title")}{" "}
                <span className="" style={{ color: "#DB1616" }}>
                  {" "}
                  &nbsp;({Number(highJobs?.count).toLocaleString()})
                </span>
              </h1>
            )}
            {isLoading ? (
              <>
                <Grid
                  container
                  spacing={1}
                  sx={{ display: { xs: "flex", md: "none" }, m: 3 }}
                >
                  <Grid item>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      width={200}
                      height={40}
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      width={40}
                      height={40}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <h1
                  className="d-lg-none text-center responsive_header"
                  style={{
                    color: "#424447",
                    fontWeight: 700,
                    fontFamily: "Epilogue,sans-serif",
                  }}
                >
                  {t("homepage:high-paid-jobs.title")}{" "}
                  <span className="" style={{ color: "#DB1616" }}>
                    {" "}
                    &nbsp;({Number(highJobs?.count).toLocaleString()})
                  </span>
                </h1>{" "}
                {/** Mobile View */}
              </>
            )}
          </Grid>
          <Grid item>
            {isLoading ? (
              <Skeleton
                animation="wave"
                variant="rounded"
                width={300}
                height={50}
                sx={{ display: { xs: "none", md: "flex" } }}
              />
            ) : (
              // <Link to='/search/jobs' className='d-none d-lg-block' style={{ fontSize: '28px', color: '#2a9df4', padding: '10px 60px 10px 60px', border: '1px solid ', borderRadius: '10px' }}>EXPLORE ALL</Link>
              <Link
                to={{
                  pathname: "/search/jobs",
                  state: { fromHome: true },
                }}
              >
                <Button
                  onClick={() => setHighPaid(true)}
                  className="d-none d-lg-block"
                  size="large"
                  variant="outlined"
                >
                  {t("button:explore-all.title")}
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </div>
      <div
        className="container high_paid_job_slider"
        style={{ marginTop: "50px", marginBottom: "70px" }}
      >
        {/** Slider Component */}
        {slider}
      </div>
      <div className="text-c">
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="rounded"
            width={300}
            height={50}
            sx={{ display: { xs: "flex", md: "none" } }}
          />
        ) : (
          <Link
            to={{
              pathname: "/search/jobs",
              state: { fromHome: true },
            }}
          >
            <Button
              onClick={() => setHighPaid(true)}
              className="d-block d-lg-none"
              size="large"
              variant="outlined"
              sx={{ marginTop: "10rem !important" }}
            >
              {t("button:explore-all.title")}
            </Button>
          </Link>
        )}
      </div>
      <Box
        className="container"
        sx={{
          marginTop: "120px",
          "@media (max-width: 600px)": {
            marginTop: "60px",
          },
        }}
      >
        <hr />
      </Box>
    </div>
  );
}

export default HighPaidJobs;
