import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { postContactInformation } from '../../../fetchAPI'
import SuccessAlert from '../Alerts/SuccessAlert'
import ErrorAlert from '../Alerts/ErrorAlert'
import { validatePhoneNumber } from '../../helpers/helperFunctions'
import { useAlert } from '../../context/AlertContext'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
    input: {
        borderLeft: '1px solid #C4C4C4',
        paddingLeft: "14px",
        marginLeft: "7px",
        padding: "30px 0px",
    },
    outlinedInput: {
        '& .react-tel-input .form-control': {
            borderRadius: '4px',
            borderColor: '#C4C4C4',
            padding: '14px',
            fontSize: '16px',
            fontWeight: 400,
            color: 'rgba(0, 0, 0, 0.87)',
        },
        '& .react-tel-input .flag-dropdown': {
            border: 'none',
            backgroundColor: 'transparent',
        },
        '& .react-tel-input .form-control:focus': {
            borderColor: '#0275D8',
            boxShadow: '0 0 0 1px #0275D8',
        },
        '& .react-tel-input .selected-flag': {
            marginLeft: '8px',
            marginRight: '12px',
        },
        '& .react-tel-input .country-list': {
            zIndex: 1000,
        },
    }
});


function ContactUs() {

    const [contactData, setContactData] = useState({ source: 'candidate' })
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isBD, setIsBD] = useState(false);
    const [country, setCountry] = useState("bd");
    const [dialCode, setDialCode] = useState("");

    const alertContext = useAlert();
    const [openAlert, setOpenAlert] = useState(false)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const classes = useStyles();


    const handleChange = (name) => (event) => {
        setContactData({ ...contactData, [name]: event.target.value })
    }

    const handlePhoneChange = (value, countryData) => {
        setDialCode(countryData.dialCode);

        if (country !== countryData.countryCode) {
            setPhoneNumber("")
            setCountry(countryData.countryCode);
        }
        if (countryData.countryCode === "bd") {
            setIsBD(true)
        } else {
            setIsBD(false)
        }

        setPhoneNumber(value)
    };

    const sendContactInformation = (body) => (event) => {
        event.preventDefault();

        const actualNumber = phoneNumber.replace(`${dialCode}`, isBD ? "0" : "")

        if (actualNumber == 0 || !actualNumber) {
            alertContext.setOpenErrorAlert(true);
            alertContext.setResponseMessage("Phone number is required");
            return;
        }

        if (isBD && actualNumber != 0 && !validatePhoneNumber(actualNumber)) {
            alertContext.setOpenErrorAlert(true);
            alertContext.setResponseMessage("Please enter a valid phone number!");
            return;
        }

        const params = { ...body, phone: actualNumber != 0 ? actualNumber : "", dialCode: actualNumber != 0 ? dialCode : "" }

        postContactInformation(params).then((response) => {
            if (response && response.success) {
                setResponseMessage(response.response.message)
                setOpenAlert(true);
                // Resetting the form fields
                setContactData({ source: 'candidate', name: '', email: '', comment: '' })
                setPhoneNumber(dialCode);
            }
            else {
                setResponseMessage(response.errors[0].message);
                setOpenErrorAlert(true);
            }
        })
    }

    return (
        <>
            {/* Contact Us Block */}
            <Card className='atb-card ' style={{ backgroundColor: '#d0efff', marginBottom: '100px' }}>
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <div className="container m-t50">
                            <div className=''>
                                <h1>Contact Us</h1>
                                <p style={{ fontSize: '20px' }}>If you have any queries, feel free to reach out to us during business hours. Sunday – Thursday: 9:30 AM – 05:30 PM</p>
                            </div>
                            <div className='container m-t50'>
                                <div className='contact-info text-left d-none d-lg-block'>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <img src='/v2/images/location.png' style={{ height: '60px' }} />
                                        </div>
                                        <div className='col-10'>
                                            <p style={{ fontSize: '22px', marginBottom: '55px' }}><span class="">11th Floor, MMK-Aakash Avenue, Plot No. 12, Kamal Ataturk Avenue, Gulshan-2, Dhaka-1212, Bangladesh</span></p>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-2'>
                                            <img src='/v2/images/messege.png' style={{ height: '40px' }} />
                                        </div>
                                        <div className='col-10'>
                                            <p style={{ fontSize: '22px', marginBottom: '55px' }}><span>contact@atb-jobs.com</span></p>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-2'>
                                            <img src='/v2/images/mobile.png' style={{ height: '60px' }} />
                                        </div>
                                        <div className='col-10'>
                                            <p style={{ fontSize: '22px', marginBottom: '55px' }}><span>+8801400492818</span></p>
                                        </div>
                                    </div>

                                </div>
                                <div className='contact-info text-center d-lg-none'>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <img src='/v2/images/location.png' style={{ height: '60px' }} />
                                        </div>
                                        <div className='col-10'>
                                            <p style={{ fontSize: '22px', marginBottom: '55px' }}><span class="">11th Floor, MMK-Aakash Avenue, Plot No. 12, Kamal Ataturk Avenue, Gulshan-2, Dhaka-1212, Bangladesh</span></p>
                                        </div>
                                    </div>
                                    <p style={{ fontSize: '22px', marginBottom: '55px' }}><img src='/v2/images/messege.png' style={{ height: '40px' }} /><span class="ml-3">contact@atb-jobs.com</span></p>
                                    <p style={{ fontSize: '22px', marginBottom: '55px' }}><img src='/v2/images/mobile.png' style={{ height: '60px' }} /><span class="ml-3">+8801400492818</span></p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <img src='/v2/images/placeholder.png' width={'100%'} />
                    </div>
                </div>
            </Card>

            {/* Message Block */}
            <Card className='atb-card ' style={{ backgroundImage: 'url(/v2/images/contactUs-hero.jpg)', backgroundSize: 'cover', marginBottom: '100px' }}>
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <div className='pt-2'>
                            <Card className='atb-card'>
                                <h5 className='mt-3'>Send us a Message</h5>
                                <div className="container">
                                    <div className='text-left'>
                                        <form onSubmit={sendContactInformation(contactData)}>
                                            <div class="form-group">
                                                <input type="text" value={contactData.name} onChange={handleChange('name')} required class="form-control atb-input-box-2" placeholder="Enter Your Name" />
                                            </div>
                                            <div class="form-group">
                                                <input type="email" value={contactData.email} onChange={handleChange('email')} required class="form-control atb-input-box-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email" />
                                            </div>
                                            <div class="form-group">
                                                <textarea type="text" value={contactData.comment} onChange={handleChange('comment')} required class="form-control atb-input-box-2" id="exampleInputPassword1" placeholder="Message" />
                                            </div>
                                            <div class="form-group">
                                                <PhoneInput
                                                    required={true}
                                                    country={country}
                                                    value={phoneNumber || ''}
                                                    onChange={handlePhoneChange}
                                                    inputProps={{
                                                        name: 'mobile_number',
                                                        required: true,
                                                        // autoFocus: true,
                                                    }}
                                                    countryCodeEditable={false}
                                                    enableSearch={true}
                                                    containerClass={classes.outlinedInput}
                                                    inputStyle={{
                                                        width: `calc(100% - 20px)`,
                                                        border: '1px solid #C4C4C4',
                                                        borderRadius: '4px',
                                                        fontSize: '16px',
                                                        fontWeight: 400,
                                                        color: 'rgba(0, 0, 0, 0.87)',
                                                        outline: 'none',
                                                        paddingTop: '24px',
                                                        paddingBottom: "24px",
                                                        marginLeft: "20px",
                                                    }}
                                                    searchStyle={{ width: "90%" }}
                                                    dropdownStyle={{ width: `${window.innerWidth > 480 ? "600%" : "400%"}` }}
                                                />
                                            </div>
                                            <div className='btn-lg text-center'>
                                                <button type="submit" class="btn-lg btn btn-primary" style={{ borderRadius: '1rem' }}>SUBMIT</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        {/* No Html here for background image to be seen */}
                    </div>
                </div>
            </Card>
            <SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />
            <ErrorAlert open={openErrorAlert} message={responseMessage} onHide={() => setOpenErrorAlert(false)} />
        </>
    )
}

export default ContactUs