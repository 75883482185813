import React from "react";
import { useQuery } from "react-query";
import { getTechCompanies, getTopFeaturedCompanies } from "../../../fetchAPI";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import CandidateContextProvider from "../../context/CandidateContext";
import FeatureCompanyCard from "./FeatureCompanyCard";
import SliderComponent from "../Slider/Slider";
import { DefaultParams } from "../Slider/SliderFunctions";

const CardSkeleton = () => (
  <Skeleton variant="rounded" width={280} height={250} animation="wave" />
);

function FeaturedCompanies() {
  const { data, isLoading } = useQuery(
    "techFeatureCompanies",
    getTechCompanies,
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  return (
    <Box
      sx={{
        background: "#F9F9F9",
        pt: { xs: 4, md: 10 },
        pb: { xs: 14, md: 20 },
      }}
    >
      <Box className="container">
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          {isLoading ? (
            <Skeleton
              variant="rounded"
              width={400}
              height={40}
              animation="wave"
            />
          ) : (
            <Typography
              variant="h3"
              sx={{ fontSize: { xs: "20px", lg: "24px" }, color: "#424447", mb: 1, }}
            >
              Featured Companies
            </Typography>
          )}
          <Grid item>
            {!isLoading && (
              <Link to={`/company/list?industry=Information Technology&top_companies=true`}>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "45px",
                    border: "1px solid #1A77FF",
                    cursor: "pointer",
                    mb: 3,
                  }}
                >
                  Explore All
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>

        <CandidateContextProvider>
          <Box sx={{ mt: 6 }}>
            {isLoading ? (
              <Grid container spacing={2} justifyContent="center">
                {[...Array(4)].map((_, i) => (
                  <Grid item key={i}>
                    <CardSkeleton />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <SliderComponent
                emptyText="No companies available"
                slides={data?.response?.data?.employers?.map((item, index) => (
                  <Box
                    sx={{
                      py: 1,
                      "@media (max-width: 425px)": { pl: 4.2 },
                      "@media (max-width: 375px)": { pl: 1.3 },
                      mb: { xs: 3, md: 5 },
                    }}
                  >
                    <FeatureCompanyCard key={index} item={item} />
                  </Box>
                ))}
                params={{
                  ...DefaultParams,
                  spaceBetween: 10,
                  breakpoints: {
                    400: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    992: { slidesPerView: 3 },
                    1200: { slidesPerView: 3 },
                    1400: { slidesPerView: 4 },
                    3000: { slidesPerView: 5 },
                  },
                  virtual: false,
                }}
              />
            )}
          </Box>
        </CandidateContextProvider>
      </Box>
    </Box>
  );
}

export default FeaturedCompanies;
