import { Box, Button, Card, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { exploreByCategoryClickTracking, getCategoryData } from '../../../fetchAPI'
import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom";

const JobCardSkeleton = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                border: '1px solid #e0e0e0',
                padding: 3,
                width: {
                    xs: '100%',
                }
            }}
        >
            <Grid container spacing={2} columnGap={2}>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" width={50} height={50} animation="wave" />
                </Grid>
                <Grid item xs={8}>
                    <Skeleton variant="text" width={"80%"} height={30} animation="wave" />
                    <Box sx={{ display: "flex", gap: 2 }}>
                        <Skeleton variant="text" width={"60%"} height={30} animation="wave" />
                        <Skeleton variant="text" width={"60%"} height={30} animation="wave" />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

function LoadingSkeletonCategory() {
    return (
        <div className='row' style={{ marginTop: "60px" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box sx={{ display: 'flex', flexDirection: "column", rowGap: 3 }}>
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                    </Box>
                </Grid>
                <Grid item sm={6} md={4} lg={3} sx={{ display: { xs: "none", sm: "block" } }}>
                    <Box sx={{ display: 'flex', flexDirection: "column", rowGap: 3 }}>
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                    </Box>
                </Grid>
                <Grid item md={4} lg={3} sx={{ display: { xs: "none", md: "block" } }}>
                    <Box sx={{ display: 'flex', flexDirection: "column", rowGap: 3 }}>
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                    </Box>
                </Grid>
                <Grid item md={4} lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
                    <Box sx={{ display: 'flex', flexDirection: "column", rowGap: 3 }}>
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

function CategoryBox() {

    const [jobsCategory, setJobCategory] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        settingCategoryData()
    }, [])

    const settingCategoryData = () => {

        getCategoryData().then((response) => {
            if (response && response.success) {
                setJobCategory(response?.response?.data)
                setIsLoading(false)
            }
            else {
                // console.log('error: ', response)

            }
        })
    }

    //translation
    const { t } = useTranslation(["homepage", "button"])

    const history = useHistory();  

    // -- category click tracking handler --
    const trackCategoryClick = async (category) =>{
          const response = await exploreByCategoryClickTracking(category?.id);
          if(response){
             history.push(
               `/search/jobs?tracking_page=home&department=${encodeURIComponent(
                 category.name
               )}`,
               {
                 fromHome: true,
               }
             );
          }
    }
  
    return (
        <Box className='container' sx={{ marginTop: "145px", '@media (max-width: 991px)': { marginTop: "75px" }, paddingX: "30px" }}>

            {/* Title */}
            <Stack spacing={2} direction={'row'} justifyContent={{ xs: 'center', md: 'space-between' }} alignItems={'center'}>
                <Box>
                    {
                        isLoading ?
                            <>
                                <Stack direction={'row'} spacing={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    <Skeleton animation='wave' variant="rounded" width={300} height={40} />
                                    <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                </Stack>

                            </>
                            :
                            <Typography variant='h3' sx={{ color: '#424447', fontWeight: 700, fontSize: { xs: '20px', sm: '20px', lg: '28px' } }}>{t("homepage:explore-by-category.title")}</Typography>

                    }
                </Box>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    {
                        isLoading ?
                            <Skeleton animation='wave' variant="rounded" width={150} height={50} />
                            :
                            <Link to='/all-categories'><Button size='large' variant='outlined' style={{ float: 'right' }}>{t("button:explore-all.title")}</Button></Link>
                    }
                </Box>
            </Stack>

            {/* Desktop category Cards */}
            {
                isLoading ?
                    <LoadingSkeletonCategory />
                    :
                    <Box sx={{ marginTop: "33px", '@media (max-width: 991px)': { marginTop: "15px" } }}>
                        <Grid container spacing={2} mb={10}>
                            {
                                jobsCategory ?
                                    jobsCategory?.map((category, index) => {
                                        return (
                                          <Grid
                                            item
                                            key={index}
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            xl={3}
                                          >
                                              <Card
                                                onClick={() => trackCategoryClick(category)}
                                                sx={{
                                                  padding: 3,
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  justifyContent: "center",
                                                  height: "100%",
                                                  cursor:'pointer'
                                                }}
                                              >
                                                <Stack
                                                  spacing={5}
                                                  direction={"row"}
                                                  alignItems={"center"}
                                                >
                                                  <img
                                                    src={category?.icon}
                                                    width={64}
                                                    height={64}
                                                    alt="Category icon"
                                                  />
                                                  <Stack
                                                    spacing={1}
                                                    sx={{
                                                      marginLeft:
                                                        "25px !important",
                                                    }}
                                                  >
                                                    <Typography
                                                      variant="h3"
                                                      sx={{
                                                        fontSize: "16px",
                                                        fontWeight: 500,
                                                      }}
                                                    >
                                                      {category?.name}
                                                    </Typography>
                                                    <Typography
                                                      variant="h3"
                                                      sx={{
                                                        fontSize: "16px",
                                                        fontWeight: 600,
                                                        color: "#0079C1",
                                                      }}
                                                    >
                                                      {`[${category?.job_count}]`}
                                                    </Typography>
                                                  </Stack>
                                                </Stack>
                                              </Card>
                                          </Grid>
                                        );
                                    })
                                    :
                                    <Box
                                        component="span"
                                        sx={{
                                            width: 1500,
                                            height: 70,
                                            p: 3,
                                            border: '1px dashed grey',
                                            margin: 3
                                        }}>

                                        <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Jobs Available</Typography>
                                    </Box>
                            }
                        </Grid>

                    </Box>
            }

            <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: "center", marginTop: '-42px' }}>
                {
                    isLoading ?
                        <Skeleton animation='wave' variant="rounded" width={150} height={50} sx={{ marginY: "30px" }} />
                        :
                        <Link to='/all-categories'><Button className='text-center' size='large' variant='outlined' sx={{ md: { marginTop: '20px !important' } }}>{t("button:explore-all.title")}</Button></Link>
                }
            </Box>
        </Box>
    )
}

export default CategoryBox