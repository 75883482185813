import React, { useContext } from "react";

import { useQuery } from "react-query";

import { Link } from "react-router-dom";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";

import HighPaidJobCard from "./HighPaidJobCard";
import { techHighPaidJobs } from "../../../fetchAPI";
import { CandidateContext } from "../../context/CandidateContext";

const CardSkeleton = () => (
  <Skeleton variant="rounded" width={280} height={250} animation="wave" />
);

function HighPaidJobs() {
  const {
    candidateData,
    settingCandidateData,
    candidateDataLoading,
  } = useContext(CandidateContext);
  const { data, isLoading } = useQuery("techHighPaidJobs", techHighPaidJobs, {
    keepPreviousData: true,
    staleTime: Infinity,
  });

  return (
    <>
      {data?.response?.data?.high_paid_jobs &&
        data?.response?.data?.high_paid_jobs.length > 0 && (
          <Box
            sx={{
              background: "#E5F5FF",
              py: { xs: 4, md: 10 },
            }}
          >
            <Box className="container">
              <Grid
                container
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: 2,
                }}
              >
                {isLoading ? (
                  <Skeleton
                    variant="rounded"
                    width={400}
                    height={40}
                    animation="wave"
                  />
                ) : (
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: { xs: "20px", lg: "24px" },
                      color: "#424447",
                    }}
                  >
                    Highly Paid Jobs
                  </Typography>
                )}
                <Grid item>
                  {!isLoading && (
                    <Link
                      to={`/search/jobs?industry=Information+Technology&salary_range_from=100000&salary_range_to=200000`}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "45px",
                          border: "1px solid #1A77FF",
                        }}
                      >
                        Explore All
                      </Button>
                    </Link>
                  )}
                </Grid>
              </Grid>
              <Box sx={{ mt: 3 }}>
                {isLoading ? (
                  <Grid container spacing={2} justifyContent="center">
                    {[...Array(4)].map((_, i) => (
                      <Grid item key={i} xs={12} md={6}>
                        <CardSkeleton />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid container spacing={4}>
                    {data?.response?.data?.high_paid_jobs

                      ?.slice(0, 4)
                      .map((item, index) => (
                        <Grid item key={index} xs={12} md={6}>
                          <HighPaidJobCard
                            item={item}
                            candidateData={candidateData}
                            settingCandidateData={settingCandidateData}
                            candidateDataLoading={candidateDataLoading}
                          />
                        </Grid>
                      ))}
                  </Grid>
                )}
              </Box>
            </Box>
          </Box>
        )}
    </>
  );
}

export default HighPaidJobs;
