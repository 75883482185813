import React, { useEffect, useContext, forwardRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Button,
  Chip,
} from "@mui/material";
import { UserContext } from "../../context/UserContext";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { useLocation } from "react-router-dom";
import { fetchIpAddress } from "../../helpers/helperFunctions";
import { popUpLog, postActivityLogs } from "../../../fetchAPI";
import { useParams } from "react-router-dom";

function CrossIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 17L17 1"
        stroke="#72737C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 17L1 1"
        stroke="#72737C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function PromotionalMobileModal({
  promotionalAlertOpen,
  handleClose,
  handleClick,
}) {
  const [imageLoaded, setImageLoaded] = useState(false);

  // -- check device is android or ios --
  const [isAndroid, setIsAndroid] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    // -- detect Android and iOS --
    const userAgent = navigator.userAgent.toLowerCase();
    
    if (userAgent.indexOf("android") > -1) {
      setIsAndroid(true);
    } else if (
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1
    ) {
      setIsIOS(true);
    }
  }, []);

  return (
    <Dialog
      open={promotionalAlertOpen}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          paddingTop: 0,
          maxHeight: "70vh",
          minHeight: "520px",
          overflowY: "hidden",
        }}
        onClick={handleClick}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: imageLoaded ? "auto" : "300px",
              aspectRatio: "16/9",
              position: "relative",
            }}
          >
            {!imageLoaded && (
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  minHeight: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#ffff",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <img
              src={
                isAndroid
                  ? "/images/Popup-mobileLarge.png"
                  : isIOS
                  ? "/images/Popup-mobile-app-storeLarge.png"
                  : "/images/Popup-mobileLarge.png"
              }
              alt="Download atb-jobs app"
              width={"100%"}
              style={{
                objectFit: "cover",
                display: imageLoaded ? "block" : "none",
                minHeight: "500px",
              }}
              onLoad={() => setImageLoaded(true)}
            />
          </Box>

          {imageLoaded && (
            <Button
              variant="contained"
              endIcon={<ArrowCircleRightOutlinedIcon />}
              onClick={handleClick}
              sx={{
                borderRadius: "25px",
                backgroundColor: "#00457c",
                color: "white",
                textTransform: "none",
              }}
            >
              Continue in App
            </Button>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

function PromotionalWebModal({
  promotionalAlertOpen,
  handleClose,
  handleClick,
}) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [timerComplete, setTimerComplete] = useState(false);
  const [timer, setTimer] = useState(false);

  let counter = 6;

  const logEverySecond = () => {
    const interval = setInterval(() => {
      counter--;
      setTimer({ ...timer, counter });

      if (counter === 0) {
        clearInterval(interval);
        setTimerComplete(true);
      }
    }, 1000);
  };

  useEffect(() => {
    logEverySecond();
  }, []);

  return (
    <Dialog
      open={promotionalAlertOpen}
      TransitionComponent={Transition}
      onClose={timerComplete ? handleClose : handleClick}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {timerComplete ? (
          <IconButton onClick={handleClose}>
            <CrossIcon />
          </IconButton>
        ) : (
          <Box p={2}>
            <Chip
              label={`Skip in ${timer.counter}`}
              sx={{
                color: "white",
                backgroundColor: "#00457c",
              }}
            />
          </Box>
        )}
      </Box>
      <DialogContent onClick={handleClick}>
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: imageLoaded ? "auto" : "300px",
              aspectRatio: "16/9",
              position: "relative",
            }}
          >
            {!imageLoaded && (
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#ffff",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <img
              src="/images/Popup-desktopLarge.png"
              alt="Download atb-jobs app from playstore"
              width={"100%"}
              height={"auto"}
              style={{
                objectFit: "cover",
                display: imageLoaded ? "block" : "none",
              }}
              onLoad={() => setImageLoaded(true)}
              onError={(e) => {
                console.error("Error loading image:", e);
                setImageLoaded(false);
              }}
            />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default function PromotionalAlertModal() {
  const {
    promotionalMobileAlertOpen,
    setPromotionalMobileAlertOpen,
    promotionalWebAlertOpen,
    setPromotionalWebAlertOpen,
  } = useContext(UserContext);

  const location = useLocation();
  const { job_id } = useParams();
  const pageTitle = "1:home page";
  const currentPageURL = window.location.href;
  const userAgent = navigator.userAgent;

  useEffect(() => {
    const logActivity = async () => {
      const ipAddress = await fetchIpAddress();
      const params = {
        page_title: pageTitle,
        action_type: "view",
        page_url: currentPageURL,
        ip: ipAddress,
        job_post_id: job_id,
        platform: "web",
        user_agent: userAgent,
      };
      await postActivityLogs(params);
    };

    logActivity();
  }, []);

  const handleClose = () => {
    setPromotionalMobileAlertOpen(false);
    setPromotionalWebAlertOpen(false);
  };

  const handleClick = async () => {
    const userAgent = navigator.userAgent.toLowerCase();
    let url = '';

    // -- based on device url change --
    if (userAgent.indexOf("android") > -1) {
      url = "https://play.google.com/store/apps/details?id=com.candidate.atb_jobs"; 
    } else if (userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1) {
      url = "https://apps.apple.com/us/app/atb-jobs/id6739555174"; 
    } else {
      url =
        "https://play.google.com/store/apps/details?id=com.candidate.atb_jobs"; 
    }

    // -- open the URL in a new tab --
    window.open(url, "_blank");

    const ip = await fetchIpAddress();
    const platform = "web";
    const popup_id = 1;

    popUpLog(popup_id, ip, platform).then((response) => {
      if (response && response.success) {
        // console.log("ok");
      }
    });
  };

  const handleResize = async () => {
    await delay(1000);
    if (window.innerWidth <= 600) {
      setPromotionalMobileAlertOpen(true);
      setPromotionalWebAlertOpen(false);
    } else {
      setPromotionalWebAlertOpen(true);
      setPromotionalMobileAlertOpen(false);
    }
  };

  //   -- promotional add popup for mobile device --
  useEffect(() => {
    const currentPath = location.pathname;
    const allowedPaths = ["/", "/search/jobs"];

    if (!allowedPaths.includes(currentPath)) return;

    const isPopupAllowed = () => {
      const lastShownTime = localStorage.getItem("promotionalModalLastShown");

      if (!lastShownTime) return true;

      const lastShownDate = new Date(parseInt(lastShownTime));
      const currentDate = new Date();
      const hoursDifference = (currentDate - lastShownDate) / (1000 * 60 * 60);

      return hoursDifference >= 24;
    };

    const handleScroll = (threshold) => {
      const scrollPosition = window.scrollY;
      const totalHeight =
        document.documentElement.scrollHeight - window.innerHeight;

      if (scrollPosition / totalHeight >= threshold) {
        if (isPopupAllowed()) {
          // -- store current timestamp when showing the popup --
          localStorage.setItem(
            "promotionalModalLastShown",
            Date.now().toString()
          );
          handleResize();
        }
      }
    };

    if (allowedPaths.includes(currentPath) && isPopupAllowed()) {
      let threshold = 0.2;

      if (currentPath === "/search/jobs") {
        threshold = 0.001;
      }

      const scrollHandler = () => handleScroll(threshold);
      window.addEventListener("scroll", scrollHandler);

      return () => window.removeEventListener("scroll", scrollHandler);
    }
  }, [location.pathname]);

  return (
    <React.Fragment>
      <PromotionalMobileModal
        promotionalAlertOpen={promotionalMobileAlertOpen}
        handleClose={handleClose}
        handleClick={handleClick}
      />
      {/* <PromotionalWebModal
                promotionalAlertOpen={promotionalWebAlertOpen}
                handleClose={handleClose}
                handleClick={handleClick}
            /> */}
    </React.Fragment>
  );
}
