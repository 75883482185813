import { Box } from '@mui/material';
import React from 'react';
import { useHistory } from "react-router-dom";

const CompanyLogoCard = ({item}) => {
    const router = useHistory();

    return (
      <Box
        sx={{
          py: 1,
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background:'white'
        }}
        onClick={() => router.push(`/employer/profile/${item?.employer_slug}`)}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",
            height: "95px",
            objectFit: "contain",
            objectPosition: "center",
            padding: '5px',
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              objectPosition: "center",
              paddingLeft:'10px',
              paddingRight:'10px'
            }}
            src={
              item?.logo ? item.logo : "/images/company_placeholder_icon.png"
            }
            alt="company logo"
          />
        </Box>
      </Box>
    );
};

export default CompanyLogoCard;