import React, { useEffect, useRef, useState } from "react";
import Index from "./route";
import UserContextProvider from "./v2/context/UserContext";
import "./css/plugins.css";
import "./css/style.css";
import "./css/templete.css";
import "./css/skin/skin-1.css";
import "./plugins/slick/slick.min.css";
import "./plugins/slick/slick-theme.min.css";
import NorthIcon from "@mui/icons-material/North";
import { QueryClient, QueryClientProvider } from "react-query";
import { useLocation } from "react-router-dom";

//v2 styles
import "./css/v2/style.css";
// import ReactGA from 'react-ga';
import PromotionalAlertToaster from "./v2/components/Alerts/PromotionalAlertToaster";
import AlertContextProvider from "./v2/context/AlertContext";
import InitialLoader from "./v2/components/InitialLoader";
import DeleteConfirmationModalContextProvider from "./v2/context/DeleteConfirmationModalContext";
import PromotionalAlertModal from "./v2/components/Alerts/PromotionalAlertModal";
import { socialMediaTrafficLog } from "./fetchAPI";
// const TRACKING_ID = "UA-221293204-1"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

const queryClient = new QueryClient();

// const SignUp = lazy(() => import('./markup/Pages/Register2'));
//const ForgotPassword = lazy(() => import('./markup/pages/ForgotPassword'));
/*  const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./markup/Pages/Loginpage2')), 500);
  });
});  */

function App() {
  const [globalLoader, setGlobalLoader] = useState(true);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const scrollToTop = useRef();

  const globalLoading = () => {
    setTimeout(() => {
      setGlobalLoader(false);
    }, 500);
  };

  useEffect(() => {
    globalLoading();
  }, []);

  useEffect(() => {
    window.scroll({
      top: position.top,
      left: position.left,
      behavior: "smooth",
    });
  });

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      window.scrollY > 200
        ? (scrollToTop.current.style.display = "inline-block")
        : (scrollToTop.current.style.display = "none");
    });
  });

  // -- social media traffic tracking  --
  const location = useLocation();

  const generateSessionId = () => {
    return `session_${Date.now()}_${Math.random()
      .toString(36)
      .substr(2, 9)}`;
  };

  useEffect(() => {
    // check if socialTracking exists or not in sessionStorage
    const socialTracking = sessionStorage.getItem("socialTracking");

    if (socialTracking) {
      const { socialId, fb, linkedIn } = JSON.parse(socialTracking);
      const tags = fb ? "fbcl" : linkedIn ? "licl" : "";
      socialMediaTrafficLog(socialId, tags, location.pathname);
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    const hasFbcl = searchParams.has("fbcl");
    const hasLicl = searchParams.has("licl");

    if (!socialTracking && (hasFbcl || hasLicl)) {
      const socialId = generateSessionId();
      const storageObject = { socialId };

      if (hasFbcl) {
        storageObject.fb = "fbcl";
      } else if (hasLicl) {
        storageObject.linkedIn = "licl";
      }

      sessionStorage.setItem("socialTracking", JSON.stringify(storageObject));

       // call socialMediaTrafficLog API immediately when session is created
      const tags = hasFbcl ? "fbcl" : hasLicl ? "licl" : "";
      socialMediaTrafficLog(socialId, tags, location.pathname);
    }
  }, [location.search, location.pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <AlertContextProvider>
        <DeleteConfirmationModalContextProvider>
          <UserContextProvider>
            {/* <PromotionalAlertToaster /> */}
            <PromotionalAlertModal />
            {globalLoader ? (
              <InitialLoader />
            ) : (
              <>
                <Index />
                <div
                  style={{ display: "none" }}
                  onClick={() => setPosition({ ...position, top: 0, left: 0 })}
                  className="scroll-to-top"
                  ref={scrollToTop}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <NorthIcon sx={{ fontSize: "32px" }} />
                  </div>
                </div>
              </>
            )}
          </UserContextProvider>
        </DeleteConfirmationModalContextProvider>
      </AlertContextProvider>
    </QueryClientProvider>
  );
}

export default App;
