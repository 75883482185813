import React, { useEffect } from "react";
import HeroSection from "./HeroSection";
import FeaturedCompanies from "./FeaturedCompanies";
import RemotePositions from "./RemotePositions";
import ItRoles from "./ItRoles";
import HighPaidJobs from "./HighPaidJobs";
import PopularRoles from "./PopularRoles";
import { fetchIpAddress } from "../../helpers/helperFunctions";
import { postActivityLogs } from "../../../fetchAPI";
import LongFooter from "../LongFooter";
import CandidateContextProvider from "../../context/CandidateContext";
import UserContextProvider from "../../context/UserContext";
import AlertContextProvider from "../../context/AlertContext";
import CompanyLogos from "./CompanyLogos";

function EngineeringRoute() {
  const pageTitle = "engineering";
  const currentPageURL = window.location.href;
  const userAgent = navigator.userAgent;

  // -- activity log --
  useEffect(() => {
    const logActivity = async () => {
      const ipAddress = await fetchIpAddress();
      const params = {
        page_title: pageTitle,
        action_type: "view",
        page_url: currentPageURL,
        ip: ipAddress,
        platform: "web",
        user_agent: userAgent,
      };
      await postActivityLogs(params);
    };

    logActivity();
  }, []);
  return (
    <div>
      <CandidateContextProvider>
        <UserContextProvider>
          <AlertContextProvider>
            <HeroSection />
            <CompanyLogos/>
            <FeaturedCompanies />
            <PopularRoles />
            {/* <RemotePositions /> */}
            <HighPaidJobs />
            <ItRoles />
            <LongFooter />
          </AlertContextProvider>
        </UserContextProvider>
      </CandidateContextProvider>
    </div>
  );
}

export default EngineeringRoute;
