import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const slideItems = [
  {
    id: 1,
    image: "/v2/images/dev-roles-1.png",
    roles: [
      "Developers",
      "Java Developers",
      "Golang Developers",
      "Software Engineers",
      "CSS Developers",
      "AngularJS Developers",
    ],
  },
  {
    id: 2,
    image: "/v2/images/dev-roles-2.png",
    roles: [
      "Web Developers",
      "Full Stack Developers",
      "Mobile App Developers",
      "iOS Developers",
      "Python Developers",
      "Vue JS Developers",
      "DevOps Engineers",
    ],
  },
  {
    id: 3,
    image: "/v2/images/dev-roles-3.png",
    roles: [
      "PHP Developers",
      "HTML Developers",
      "WordPress Developers",
      "Front End Developers",
      "MySQL Developers",
      "Ruby on Rails Developers",
      "Software QA Testers",
    ],
  },
  {
    id: 4,
    image: "/v2/images/dev-roles-4.png",
    roles: [
      "React Native Developers",
      "Rust Developers",
      "Ruby Developers",
      "Scala Developers",
      "C# Developers",
      "Magento Developers",
    ],
  },
];

function ItRoles() {
  return (
    <Box
      sx={{
        background: "#ffffff",
        py: { xs: 5, md: 10 },
      }}
    >
      <Box className="container">
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            pb: 3,
          }}
        >
          <Typography
            variant="h3"
            sx={{ fontSize: { xs: "20px", lg: "24px" }, color: "#424447" }}
          >
            Development & IT Roles
          </Typography>

          <Grid item>
            <Link to="/search/jobs?industry=Information+Technology">
              <Button
                variant="outlined"
                sx={{ borderRadius: "45px", border: "1px solid #1A77FF" }}
              >
                Explore all
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Box>
          <Grid container spacing={3}>
            {slideItems.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} lg={3}>
                <Box>
                  <img style={{ width: "100%" }} src={item.image} alt="" />
                  {item.roles.map((role, index) => (
                    <Link key={index} to={`/search/jobs?keyword=${role}`}>
                      <Typography
                        key={index}
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          fontSize: "16px",
                          color: "#424447",
                          pt: 1,
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {role}
                      </Typography>
                    </Link>
                  ))}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default ItRoles;
