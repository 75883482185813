import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { UserContext } from "../../context/UserContext";
import { useHistory, Link } from "react-router-dom";
import { CandidateContext } from "../../context/CandidateContext";
import moment from "moment";
import {
  deleteCandidateSaveJobInfo,
  postActivityLikes,
  postCandidateSaveJobInfo,
} from "../../../fetchAPI";
import { fetchIpAddress } from "../../helpers/helperFunctions";
import { useAlert } from "../../context/AlertContext";
import EmployerProfilePicture from "../EmployerComponents/EmployerProfilePicture";
import ChipComponent from "../ChipComponent";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import CXOApplyToJobModal from "../Job/ApplyScreen/CXOApplyToJobModal";
import ApplyToJobModal from "../Job/ApplyScreen/ApplyToJobModal";

function SaveJobIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.6875 1.71289C12.3186 1.71289 10.7965 3.26301 10 4.65574C9.20348 3.26301 7.68145 1.71289 5.3125 1.71289C2.30613 1.71289 0 4.01543 0 7.0693C0 10.3807 2.88922 12.6357 7.20363 16.05C9.31621 17.7204 9.45195 17.8473 10 18.2879C10.4473 17.9284 10.6948 17.7117 12.7964 16.05C17.1108 12.6357 20 10.3807 20 7.0693C20 4.01547 17.6939 1.71289 14.6875 1.71289Z"
        fill="#DB1616"
      />
    </svg>
  );
}

function ThumbsUpFillIcon({ color = "#1976D2", size = "24px" }) {
  return (
    <svg
      fill={color}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
    </svg>
  );
}

function ThumbsUpIcon({ color = "#1976D2", size = "24px" }) {
  return (
    <svg
      fill={color}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.2s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z" />
    </svg>
  );
}

function QualificationIcon() {
  return (
    <svg
      width="19"
      height="12"
      viewBox="0 0 19 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1185_517)">
        <path
          d="M9.24204 6.20568C8.67357 6.21382 8.04876 5.92772 7.40767 5.71798C5.70415 5.15953 4.00438 4.59106 2.30336 4.02009C1.67729 3.81223 1.05811 3.60313 0.438306 3.38464C0.231704 3.31201 -0.00432234 3.22311 6.01223e-05 2.95828C0.00444258 2.69346 0.243601 2.60769 0.451455 2.5432C1.53663 2.20387 2.62286 1.86997 3.71012 1.5415C5.22771 1.08384 6.7503 0.644971 8.26224 0.171039C8.98023 -0.0609193 9.75302 -0.0609193 10.471 0.171039C12.8125 0.891014 15.1627 1.58282 17.5092 2.2884C17.7885 2.37229 18.0652 2.46682 18.3394 2.56511C18.5272 2.63398 18.7276 2.72726 18.7232 2.96454C18.7188 3.20182 18.5197 3.29574 18.3313 3.36147C17.682 3.58999 17.0303 3.80974 16.3773 4.02761C14.7063 4.58606 13.036 5.14638 11.3625 5.69607C10.7114 5.91456 10.0772 6.20381 9.24204 6.20568Z"
          fill="#8743DF"
        />
        <path
          d="M3.62329 7.12675C3.62329 6.63592 3.62329 6.1455 3.62329 5.6555C3.62329 5.14839 3.63331 5.13461 4.10223 5.28737C4.96495 5.56848 5.82391 5.85835 6.68412 6.14759C7.22755 6.32978 7.76784 6.52323 8.31189 6.70103C9.06317 6.94583 9.80819 6.91077 10.5538 6.65471C11.8748 6.20081 13.2002 5.75818 14.5256 5.31555C15.0947 5.12773 15.0966 5.12773 15.0966 5.71373C15.0966 6.63217 15.069 7.55123 15.1066 8.46842C15.1304 9.06067 14.8562 9.42943 14.401 9.72869C13.5483 10.2865 12.5986 10.5927 11.6119 10.793C10.0637 11.1097 8.46631 11.0986 6.92266 10.7604C5.9917 10.5576 5.09267 10.2596 4.29318 9.71929C3.83866 9.41315 3.56319 9.03062 3.61578 8.44274C3.65459 8.00575 3.62329 7.56437 3.62329 7.12675Z"
          fill="#8743DF"
        />
        <path
          d="M17.6758 5.73235C17.6758 5.76366 17.6758 5.79496 17.6758 5.82626C17.6142 6.52036 17.7441 7.21805 18.0514 7.84345C18.4702 8.71994 18.2611 9.27838 17.4616 9.82056C17.3202 9.91697 17.2112 9.92198 17.0653 9.8312C16.3141 9.36541 16.105 8.75312 16.4167 7.9361C16.5132 7.68568 16.6002 7.42523 16.7448 7.19422C16.8274 7.06274 16.8349 6.92063 16.8343 6.7735C16.8343 6.14744 16.8343 5.52137 16.8343 4.89531C16.8343 4.61045 17.3026 4.2586 17.5706 4.33873C17.6958 4.37567 17.6695 4.48523 17.6701 4.57351C17.6776 4.96104 17.6751 5.3467 17.6758 5.73235Z"
          fill="#8743DF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1185_517">
          <rect width="18.7237" height="11.02" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function LocationIcon() {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0C2.46699 0 0.40625 2.06074 0.40625 4.59375C0.40625 5.40455 0.620469 6.20168 1.02573 6.89895C1.1202 7.06151 1.22581 7.21992 1.33967 7.3698L4.80041 12H5.19957L8.66033 7.36983C8.77416 7.21992 8.87977 7.06153 8.97427 6.89897C9.37953 6.20168 9.59375 5.40455 9.59375 4.59375C9.59375 2.06074 7.53301 0 5 0ZM5 6.11719C4.15998 6.11719 3.47656 5.43377 3.47656 4.59375C3.47656 3.75373 4.15998 3.07031 5 3.07031C5.84002 3.07031 6.52344 3.75373 6.52344 4.59375C6.52344 5.43377 5.84002 6.11719 5 6.11719Z"
        fill="#ED7200"
      />
    </svg>
  );
}

const CardSkeleton = () => (
  <Skeleton variant="rounded" width={50} height={32} animation="wave" />
);

function HighPaidJobCard({
  item,
  candidateData,
  settingCandidateData,
  candidateDataLoading,
}) {
  const { userData, isLoggedin } = useContext(UserContext);
  const alertContext = useAlert();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openCxoApply, setOpenCxoApply] = useState(false);
  const [openJobApply, setOpenJobApply] = useState(false);
  const [applyJobInfo, setApplyJobInfo] = useState("");
  const isCXOJobs = item?.job_post?.is_cxo
    ? item?.job_post?.is_cxo == 1
    : item?.is_cxo == 1;
  const isDashboardPage = history.location.pathname.includes(
    "/candidate-dashboard"
  );

  const [likeLoading, setLikeLoading] = useState(false);
  const [likedJob, setLikedJob] = useState(false);
  const [savedJob, setSavedJob] = useState(false);
  const [likeCount, setlikeCount] = useState(0);
  const pageTitle = isCXOJobs ? "cxo-jobs" : "regular-jobs";
  const currentPageURL = window.location.href;
  const userAgent = navigator.userAgent;
  const isEmployer = +userData?.user_role_id === 3;
  let isExpired = false;
  const { appliedJobsIds } = useContext(CandidateContext);
  const isApplied = appliedJobsIds.includes(
    parseInt(item?.job_post?.post_id ? item?.job_post?.post_id : item?.post_id)
  );

  const displaySalary = (item) => {
    const jobPost = item?.job_post || item;

    if (jobPost?.negotiable) {
      return "Negotiable";
    }

    const minSalary = jobPost?.minimum_salary;
    const maxSalary = jobPost?.maximum_salary;
    const currency = jobPost?.salary_currency?.name || "Taka";
    const salaryType =
      jobPost?.salary_type?.type || jobPost?.salary_type_data?.type || "";

    if (maxSalary !== null) {
      return `${minSalary} - ${maxSalary} ${currency}/${salaryType}`;
    }
    if (minSalary !== null) {
      return `${minSalary} ${currency}/${salaryType}`;
    } else {
      return "Negotiable";
    }
  };

  const dayLeft = (date) => {
    const today = moment(new Date(), "YYYY-MM-DD").startOf("day");
    const targetDate = moment(date, "YYYY-MM-DD").startOf("day");

    let days = targetDate.diff(today, "days");
    if (days >= 0) {
      return days;
    } else {
      isExpired = true;
      return null;
    }
  };

  const [saveLoading, setSaveLoading] = useState(false);

  const unSaveCandidate = (candId, jobId) => {
    if (isLoggedin) {
      setSaveLoading(false);
      setLoading(true);

      deleteCandidateSaveJobInfo(candId, jobId)
        .then((response) => {
          if (response && response.success) {
            settingCandidateData();
            setSavedJob(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      history.push("/login");
    }
  };

  const saveCandidate = (candId, jobId) => {
    if (isLoggedin) {
      setLoading(false);
      setSaveLoading(true);

      postCandidateSaveJobInfo(candId, jobId)
        .then((response) => {
          if (response && response.success) {
            settingCandidateData();
            setSavedJob(true);
          } else {
            if (
              candidateData?.id == 0 &&
              response.errors[0].message === "Unauthenticated."
            ) {
              history.push("/login");
            } else {
              console.log(response);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setSaveLoading(false);
        });
    } else {
      history.push("/login");
    }
  };

  const likeJobPost = async (id) => {
    if (candidateData?.id === 0 && !isLoggedin) {
      history.push("/login");
    } else {
      if (!likedJob) {
        setLikeLoading(true);
        const ipAddress = await fetchIpAddress();
        const params = {
          page_title: pageTitle,
          action_type: "view",
          page_url: currentPageURL,
          ip: ipAddress,
          job_post_id: id,
          platform: "web",
          user_agent: userAgent,
        };
        await postActivityLikes(params).then((res) => {
          if (res && res.success) {
            setLikeLoading(false);
            setLikedJob(true);
            setlikeCount(item?.like_count + 1);
          } else {
            setLikeLoading(false);
            alertContext.setOpenErrorAlert(true);
            alertContext.setResponseMessage(
              `${res.errors.code}: ${res.errors.message}`
            );
          }
        });
      }
    }
  };

  useEffect(() => {
    if (item?.is_user_like) {
      setLikedJob(true);
    }

    if (item?.like_count) {
      setlikeCount(item?.like_count);
    }
  }, [item.is_user_like, item.like_count]);

  useEffect(() => {
    if (
      candidateData?.savedJobIds?.includes(
        parseInt(
          item?.job_post?.post_id ? item?.job_post?.post_id : item?.post_id
        )
      )
    ) {
      setSavedJob(true);
    }
  }, [candidateData?.savedJobIds, item?.job_post?.post_id, item?.post_id]);

  const handleOpenCxoModal = () => {
    // gatis for job search page. Need to fix immidiatly
    setApplyJobInfo(item);
    if (isCXOJobs) {
      setOpenCxoApply(true);
    } else {
      setOpenJobApply(true);
    }
  };

  const displayTitle = (title) => {
    if (title?.length > 10) {
      let concateText = title.substring(0, 15) + "...";
      return concateText;
    } else {
      return title;
    }
  };

  const handleRedirection = () => {
    const basePath = isCXOJobs ? "/cxo-jobs" : "/jobs";
    const postId = item?.job_post?.post_id || item?.post_id;
    const slug = item?.job_post?.slug || item?.slug;

    return `${basePath}/${postId}${slug ? `/${slug}` : ""}`;
  };
  return (
    <div>
      <Card>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", md: "space-between" },
              alignItems: "baseline",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              minHeight: { xs: "10vh", md: "13vh" },
              pb: { xs: 3, md: 1 },
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "20px", color: "#424447" }}
            >
              We are looking for “
              {item?.job_post?.job_title
                ? item?.job_post?.job_title
                : item?.job_title}
              ”
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box
                sx={{
                  cursor: `${isExpired ? "default" : "pointer"}`,
                }}
              >
                <Button
                  className="ga_apply_button"
                  variant="contained"
                  onClick={!isApplied && handleOpenCxoModal}
                  disabled={isExpired}
                  sx={{
                    color: `${isApplied ? "#15A449" : "#ffffff"}`,
                    fontSize: "12px",
                    padding: "5px 10px",
                    fontFamily: "Epilogue,sans-serif",
                    fontWeight: "700",
                    backgroundColor: `${isApplied ? "#E5F5FF" : "#1A77FF"}`,
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                    cursor: `${isApplied && "default"}`,
                    ":focus": { outline: "none" },
                    "&:hover": {
                      backgroundColor: `${isApplied ? "#E5F5FF" : "#1A77FF"}`,
                    },
                  }}
                >
                  {isApplied ? "Applied" : "Apply Now"}
                </Button>
              </Box>

              <Stack direction="row" spacing={1}>
                {candidateData?.id && candidateDataLoading ? (
                  <CardSkeleton />
                ) : candidateData?.id !== 0 && savedJob ? (
                  <Tooltip title="Saved" placement="top" arrow>
                    <LoadingButton
                      loading={loading}
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        unSaveCandidate(
                          candidateData?.id,
                          item?.job_post?.post_id
                            ? item?.job_post?.post_id
                            : item?.post_id
                        )
                      }
                      sx={{
                        flexWrap: "nowrap",
                        boxShadow: 0,
                        lineHeight: "0px",
                        padding: "3px",
                        height: "30px",
                        minWidth: "50px",
                        ":focus": { outline: "none" },
                      }}
                    >
                      {!loading ? <SaveJobIcon /> : null}
                    </LoadingButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Save Job" placement="top" arrow>
                    <LoadingButton
                      loading={saveLoading}
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        saveCandidate(candidateData?.id, {
                          job_post_id: item?.job_post?.post_id
                            ? item?.job_post?.post_id
                            : item?.post_id,
                        })
                      }
                      sx={{
                        flexWrap: "nowrap",
                        boxShadow: 0,
                        lineHeight: "0px",
                        padding: "3px",
                        height: "30px",
                        minWidth: "50px",
                        ":focus": { outline: "none" },
                      }}
                    >
                      {!saveLoading ? <FavoriteBorderIcon /> : null}
                    </LoadingButton>
                  </Tooltip>
                )}

                {candidateData?.id && candidateDataLoading ? (
                  <CardSkeleton />
                ) : (
                  <Tooltip
                    title={`${likedJob ? "Liked" : "Like Job"} - ${
                      likeCount ? likeCount : "0"
                    }`}
                    placement="top"
                    arrow
                  >
                    <LoadingButton
                      loading={likeLoading}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        likeJobPost(item?.job_post?.post_id || item?.post_id);
                      }}
                      sx={{
                        flexWrap: "nowrap",
                        boxShadow: 0,
                        lineHeight: "0px",
                        padding: "3px",
                        height: "30px",
                        minWidth: "50px",
                        ":disabled": likedJob,
                        cursor: "pointer",
                        ":focus": { outline: "none" },
                      }}
                    >
                      {!likeLoading ? (
                        likedJob ? (
                          <ThumbsUpFillIcon />
                        ) : (
                          <ThumbsUpIcon />
                        )
                      ) : null}
                    </LoadingButton>
                  </Tooltip>
                )}
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "baseline", lg: "center" },
              flexDirection: { xs: "column", lg: "row" },
              gap: 2,
              pb: 3,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {isCXOJobs ? (
                candidateData?.is_cxo_eligible ||
                (userData?.id && userData?.id === item.employer?.user_id) ? (
                  <Tooltip title="Go to Profile">
                    <Link
                      to={
                        item?.author_id[0]
                          ? `/employer/profile/${item?.author_id[0].employer_name}`
                          : `/employer/profile/${item?.employer?.slug}`
                      }
                      style={{
                        display: "flex",
                      }}
                    >
                      <EmployerProfilePicture
                        profile_picture={
                          item?.author_id[0]
                            ? `${process.env.REACT_APP_S3_BUCKET}${item?.author_id[0].profile_pic}`
                            : item?.employer?.profile_pic_base64
                        }
                        height={32}
                        width={32}
                      />
                    </Link>
                  </Tooltip>
                ) : (
                  <div
                    style={{
                      position: "relative",
                      height: "40px",
                      width: "40px",
                      background: "#DADADA",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        filter: "blur(8px)",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={
                          item?.author_id[0]
                            ? `${process.env.REACT_APP_S3_BUCKET}${item?.author_id[0].profile_pic}`
                            : item?.employer?.profile_pic_base64
                        }
                        alt="Blurry content"
                        style={{ width: "100%", height: "100%" }}
                        onContextMenu={(e) => e.preventDefault()}
                        onDragStart={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                )
              ) : (
                <Tooltip title="Go to Profile">
                  <Link
                    to={
                      isDashboardPage
                        ? `/employer/profile/${item?.author_id}`
                        : item?.author_id[0]
                        ? `/employer/profile/${item?.author_id[0].employer_name}`
                        : `/employer/profile/${item?.employer?.slug}`
                    }
                    style={{
                      display: "flex",
                    }}
                  >
                    <EmployerProfilePicture
                      profile_picture={
                        item?.author_id[0]
                          ? `${process.env.REACT_APP_S3_BUCKET}${item?.author_id[0].profile_pic}`
                          : item?.employer?.profile_pic_base64 ||
                            item?.employer?.profile_pic_s3
                      }
                      height={32}
                      width={32}
                    />
                  </Link>
                </Tooltip>
              )}
              <Typography
                className="text-left text-truncate"
                align="left"
                variant="body1"
                sx={{
                  width: "9rem",
                  fontSize: "14px",
                  color: "#0168FF",
                  fontWeight: 700,
                }}
              >
                {isCXOJobs ? (
                  candidateData?.is_cxo_eligible ||
                  (userData?.id && userData?.id === item.employer?.user_id) ? (
                    item.employer?.employer_name || (
                      <span style={{ visibility: "hidden" }}>.</span>
                    )
                  ) : (
                    <div
                      style={{
                        position: "relative",
                        width: "8rem",
                        height: "24px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        userSelect: "none",
                      }}
                    >
                      <span
                        style={{ filter: "blur(4px)" }}
                        className="text-truncate"
                      >
                        {item.employer?.employer_name}
                      </span>
                    </div>
                  )
                ) : (
                  item?.employer?.employer_name ||
                  item?.author_id[0]?.employer_name || (
                    <span style={{ visibility: "hidden" }}>.</span>
                  )
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <img
                  style={{ width: "32px", height: "32px" }}
                  src="/images/candidate/salaryIcon.png"
                  alt=""
                />
              </Box>

              {/* Salary Information */}
              <Typography
                variant="body1"
                align="left"
                sx={{
                  fontSize: "14px",
                  color: "#72737C",
                  fontWeight: "500",
                  marginLeft: "10px",
                }}
              >
                Salary: {displaySalary(item)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <img
                  style={{ width: "32px", height: "32px" }}
                  src="/images/candidate/experience-icon.png"
                  alt=""
                />
              </Box>

              {/* Salary Information */}
              <Typography
                variant="body1"
                align="left"
                sx={{
                  fontSize: "14px",
                  color: "#72737C",
                  fontWeight: "500",
                  marginLeft: "10px",
                }}
              >
                Experience :{" "}
                {item?.minimum_experience
                  ? item?.minimum_experience
                  : null + " - " + item?.maximum_experience
                  ? item?.maximum_experience
                  : item?.maximum_experience}{" "}
                Years
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", lg: "space-between" },
              alignItems: { xs: "baseline", lg: "center" },
              flexDirection: { xs: "column", lg: "row" },
              gap: 2,
              pb: 1,
            }}
          >
            <Stack direction={"row"} spacing={2}>
              <ChipComponent
                title={
                  item?.job_post?.job_type?.name
                    ? item?.job_post?.job_type?.name
                    : item?.job_type?.name
                }
              />
              <Chip
                label={
                  item?.job_post?.job_level?.candidate_level_name
                    ? item?.job_post?.job_level?.candidate_level_name
                    : item?.job_level?.candidate_level_name
                }
                avatar={
                  <Avatar sx={{ backgroundColor: "transparent" }}>
                    {QualificationIcon()}
                  </Avatar>
                }
                sx={{
                  backgroundColor: "#F2E9FF",
                  color: "#8743DF",
                  fontWeight: 600,
                }}
              />
              <Chip
                label={
                  item?.job_post?.qualification?.name
                    ? item?.job_post?.qualification?.name
                    : item?.qualification?.name
                }
                avatar={
                  <Avatar sx={{ backgroundColor: "transparent" }}>
                    {LocationIcon()}
                  </Avatar>
                }
                sx={{
                  backgroundColor: "#FFF5E2",
                  color: "#ED7200",
                  fontWeight: 600,
                }}
              />
            </Stack>
            <Box
              sx={{
                px: 2,
                py: 0.5,
                border: "1px solid #ED7200",
                borderRadius: "45px",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", fontWeight: 500, color: "#424447" }}
              >
                Deadline:{" "}
                <span style={{ color: "#ED7200" }}>
                  {item?.job_post?.job_deadline || item?.job_deadline ? (
                    <span>
                      {moment(
                        item?.job_post?.job_deadline
                          ? item?.job_post?.job_deadline
                          : item?.job_deadline
                      ).format("MMM Do, YY")}
                    </span>
                  ) : (
                    "N/A"
                  )}
                  &nbsp;
                  <span style={{ color: "#DB1616" }}>
                    {(() => {
                      const daysLeft = dayLeft(
                        item?.job_post?.job_deadline || item?.job_deadline
                      );
                      return daysLeft === 0
                        ? `(Expiring Today)`
                        : daysLeft === 1
                        ? `(${daysLeft} day left)`
                        : daysLeft > 1 && daysLeft < 8
                        ? `(${daysLeft} days left)`
                        : ``;
                    })()}
                  </span>
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Grid container spacing={2} alignItems="center" flexWrap="wrap">
              {item.skills.slice(0, 5).map((skill, i) => (
                <Grid item key={i}>
                  <Chip
                    label={skill?.skill?.skill_name}
                    sx={{ background: "#F9F9F9", color: "#72737C" }}
                  />
                </Grid>
              ))}
              {item.skills.length > 5 ? (
                <Grid item>
                  <Chip
                    label="+ 5 More"
                    sx={{ background: "#F9F9F9", color: "#72737C" }}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Link to={handleRedirection}>
              <NorthEastIcon sx={{ color: "#0168FF" }} />
            </Link>
          </Box>
        </Box>
      </Card>
      {/* open modal to apply cxo job */}
      <CXOApplyToJobModal
        open={openCxoApply}
        jobInfo={applyJobInfo.job_post}
        handleClose={() => setOpenCxoApply(false)}
      />

      {/* open modal to apply regular job */}
      <ApplyToJobModal
        open={openJobApply}
        jobInfo={applyJobInfo.job_post ? applyJobInfo.job_post : applyJobInfo}
        handleClose={() => setOpenJobApply(false)}
      />
    </div>
  );
}

export default HighPaidJobCard;
