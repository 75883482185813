import { Box, Typography, Grid } from "@mui/material";
import React, { useContext } from "react";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { Link } from "react-router-dom";
import Style from "./StyledButton.module.css";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const featureContent = [
  "Find roles tailored to your expertise and experience level.",
  "Quick and seamless application process.",
  "Get matched with companies looking for your talent.",
  "Remote and on-site roles available globally.",
];

function HeroSection() {
  const { isLoggedin } = useContext(UserContext);

  const router = useHistory();
  return (
    <Box
      sx={{
        backgroundImage: "image-set(url(/v2/images/engineering-hero-bg.png))",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        py: { xs: 4, md: 8 },
      }}
    >
      <Box className="container">
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6.5}>
            <Box>
              <Typography
                variant="h2"
                sx={{
                  color: "#ffffff",
                  lineHeight: 1.2,
                  fontSize: { xs: "28px", lg: "44px" },
                  letterSpacing: "2px",
                }}
              >
                For <span style={{ color: "#FBC53B" }}>IT/WEB engineers</span>{" "}
                <br />
                who want to change jobs based on <br /> their{" "}
                <span style={{ color: "#FBC53B" }}>technical skills</span>
              </Typography>
              <Box py={3} pl={2}>
                {featureContent.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1.5,
                      py: 1.5,
                    }}
                  >
                    <CheckBoxRoundedIcon
                      sx={{ fontSize: "24px", color: "#FBC53B" }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "14px", lg: "16px" },
                        fontWeight: "600",
                        color: "#ffffff",
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>

              {!isLoggedin && (
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <button
                    className={`${Style.glowingButtons}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() =>
                      router.push("/candidate-register?source=engineering_page")
                    }
                  >
                    Register
                    <EastRoundedIcon fontSize="24px" sx={{ ml: 1, mt: 0.5 }} />
                  </button>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "14px", lg: "16px" },
                      fontWeight: "400",
                      color: "#ffffff",
                    }}
                  >
                    Already have an account?{" "}
                    <Link to="/login">
                      <span style={{ fontWeight: 700, color: "#FBC53B" }}>
                        Log in
                      </span>
                    </Link>
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={5.5}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <img
              style={{ width: "100%" }}
              src="/v2/images/engineering-hero.png"
              alt=""
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default HeroSection;
