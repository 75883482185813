import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import SingleJobInformation from "../components/Job/JobDetails/SingleJobInformation";
import SingleCompanyInformation from "../components/Job/JobDetails/SingleCompanyInformation";
import SingleJobOpenings from "../components/Job/JobDetails/SingleJobOpening";
import SingleInterviewInformation from "../components/Job/JobDetails/SingleInterviewInformation";
import SingleFAQsInformation from "../components/Job/JobDetails/SingleFaqInformation";
import { getJobDetailsV2, postActivityLogs } from "../../fetchAPI";
import { useHistory } from 'react-router-dom';
import { fetchIpAddress } from "../helpers/helperFunctions";

function JobDetailsV2() {
  const pageTitle = "job-details";
  const currentPageURL = window.location.href;
  const userAgent = navigator.userAgent;

  const { slug, job_id } = useParams()
  const [jobData, setJobData] = useState('')
  const [jobDataLoading, setJobDataLoading] = useState(false)

  const [tabValue, setTabvalue] = useState('job_details');

  const history = useHistory();

  const handleTabChange = (event, newValue) => {
    setTabvalue(newValue);
  };

  useEffect(() => {
    setJobDataLoading(true);
    getJobDetailsV2(job_id)
      .then(response => {
        // Check if the response indicates success
        if (response && response.success) {
          setJobData(response.response.data);
        } else {
          console.error('Unexpected response format:', response);
          if (response?.errors[0]?.code === 404) {
            // -- based on notFound status redirect the user to notFound page --
            history.push('/not-found');
          }
          // Handle the error (e.g., show an error message to the user)
        }
      })
      .catch(error => {
        console.error('Error fetching job details:', error);
        // Handle the error (e.g., show an error message to the user)
      })
      .finally(() => setJobDataLoading(false));
  }, [slug, job_id]);


  useEffect(() => {
    if (jobData?.job_post?.slug) {
      const currentPath = `/jobs/${job_id}/${jobData.job_post.slug}`;
      if (window.location.pathname !== currentPath) {
        window.history.replaceState(null, "", currentPath);
      }
    }
  }, [job_id, jobData?.job_post?.slug]);

  // -- activity log --
  useEffect(() => {
    const logActivity = async () => {
      const ipAddress = await fetchIpAddress();
      const params = {
        page_title: pageTitle,
        action_type: "view",
        page_url: currentPageURL,
        ip: ipAddress,
        job_post_id: job_id,
        platform: "web",
        user_agent: userAgent,
      };
      await postActivityLogs(params);
    };

    logActivity();
  }, []);

  return (
    <>
      <Box sx={{ fontFamily: "Poppins" }}>
        <Box sx={{ width: "100%" }}>
          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "#F9FBFD",
                pt: 4,
                px: { xs: 0, md: 12 },
              }}
            >
              <TabList
                onChange={handleTabChange}
                aria-label="job details tab"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{
                  "& .MuiTab-root": {
                    color: "#72737C",
                    textTransform: "capitalize",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "28px",
                    textAlign: "left",
                    textUnderlinePosition: "from-font",
                    textDecorationSkipInk: "none",
                  },
                  "& .Mui-selected": {
                    color: "#00457C !important",
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#00457C",
                  },
                }}
              >
                <Tab label="Job Details" value="job_details" />
                <Tab label="Company Details" value="company_details" />
                <Tab label="Opening" value="opening" />
                {jobData?.job_post?.interviews?.contents?.length > 0 && (
                  <Tab label="Interview" value="interview" />
                )}
                {jobData?.employer_info?.employer_faq?.length > 0 && (
                  <Tab label="FAQ" value="faq" />
                )}
              </TabList>
            </Box>
            <TabPanel
              value="job_details"
              sx={{
                padding: { xs: "24px 0px", sm: "24px 12px", md: "24px" },
                backgroundColor: "#F9FBFD",
              }}
            >
              <SingleJobInformation
                jobDetails={jobData}
                loading={jobDataLoading}
                onTabChange={handleTabChange}
                sx={{ fontFamily: "Poppins" }}
              />
            </TabPanel>
            <TabPanel
              value="company_details"
              sx={{
                padding: { xs: "24px 0px", sm: "24px 12px", md: "24px" },
                backgroundColor: "#F9FBFD",
              }}
            >
              <SingleCompanyInformation
                companyDetails={jobData?.employer_info}
                loading={jobDataLoading}
                isCxo={jobData?.job_post?.is_cxo}
                sx={{ fontFamily: "Poppins" }}
              />
            </TabPanel>
            <TabPanel value="opening">
              <SingleJobOpenings
                companyDetails={jobData?.employer_info}
                openingsJob={jobData?.opening}
                loading={jobDataLoading}
                onTabChange={handleTabChange}
                sx={{ fontFamily: "Poppins" }}
              />
            </TabPanel>

            {jobData?.job_post?.interviews?.contents?.length > 0 && (
              <TabPanel value="interview">
                <SingleInterviewInformation
                  interviews={jobData?.job_post?.interviews}
                  loading={jobDataLoading}
                  sx={{ fontFamily: "Poppins" }}
                />
              </TabPanel>
            )}

            {jobData?.employer_info?.employer_faq?.length > 0 && (
              <TabPanel value="faq">
                <SingleFAQsInformation
                  faqs={jobData?.employer_info?.employer_faq}
                  loading={jobDataLoading}
                  sx={{ fontFamily: "Poppins" }}
                />
              </TabPanel>
            )}
          </TabContext>
        </Box>
      </Box>
    </>
  );
}

export default JobDetailsV2