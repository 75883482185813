import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./company-slider.css";
import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";
import { getITCompanies } from "../../../fetchAPI";
import { useQuery } from "react-query";
import CompanyLogoCard from "./CompanyLogoCard";
import Slider from "react-slick";

const CardSkeleton = () => (
  <Skeleton variant="rounded" width={200} height={90} animation="wave" />
);

function CompanyLogos() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 7000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { data, isLoading } = useQuery("topTechLogos", getITCompanies, {
    keepPreviousData: true,
    staleTime: Infinity,
  });

  return (
    <Box sx={{ backgroundColor: "#e5f5ff" }}>
      {isLoading ? (
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ paddingY: "70px" }}
        >
          {[...Array(4)].map((_, i) => (
            <Grid item key={i}>
              <CardSkeleton />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <Box
            className="engineering_company_slider"
            sx={{ maxWidth: "100%", margin: "0 auto", position: "relative" }}
          >
            <Slider
              {...settings}
            >
              {data?.response?.data?.employers.map((item, index) => (
                <CompanyLogoCard key={index} item={item} index={index} />
              ))}
            </Slider>
          </Box>
        </>
      )}
    </Box>
  );
}

export default CompanyLogos;
